<template>
  <v-img
    :style="`width:100%;height:100%;position:fixed;background:#2054AF`"
    src
  >
    <div style="height: 100%">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-card
            class="elevation-0 pl-5 pr-5 pt-2 pb-1"
            color="rgba(255,255,255,0.9)"
            width="350"
          >
            <v-card-title
              style="
                display: flex;
                align-itens: center;
                justify-content: center;
              "
              class="mb-5"
            >
              <img
                src="@/assets/logoBlue.png"
                alt="TransBraz"
                style="width: 350px; padding: 30px 30px 0 30px"
              />
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="validate"
              >
                <v-row align="center" dense>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      color="primary"
                      v-model="dados.login"
                      :label="'Usuário'"
                      :rules="fieldRules"
                      required
                      prepend-inner-icon="mdi-account"
                      class="textUppercase"
                      @keypress.enter="$refs.pwd.focus()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      color="primary"
                      v-model="dados.senha"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="fieldRules"
                      :type="show ? 'text' : 'password'"
                      name="input-10-1"
                      label="Senha"
                      prepend-inner-icon="mdi-lock-outline"
                      @click:append="show = !show"
                      ref="pwd"
                      @keypress.enter="validate"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-btn
                      block
                      color="primary"
                      depressed
                      large
                      class="mt-5"
                      :loading="loading"
                      :disabled="!valid || loading"
                      @click="validate"
                      >ENTRAR</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="12" class="text-center">
                    <!-- <v-btn color="error" text>ESQUECEU SUA SENHA?</v-btn> -->
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="message.snacshow"
        :color="message.type"
        :multi-line="message.mode === 'multi-line'"
        :timeout="message.timeout"
        :vertical="message.mode === 'vertical'"
        bottom
      >
        {{ message.text }}
        <v-btn dark text @click="message.snacshow = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </div>
  </v-img>
</template>
<script>
import decode from "jwt-decode";
export default {
  props: ["id"],
  data() {
    return {
      show: false,
      loading: false,
      valid: true,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 3000,
      },
      dados: {
        login: "",
        senha: "",
      },
      operador: [],
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  mounted() {
    if (sessionStorage.getItem("auth-token")) {
      this.showLogin = false;
      if (this.$store.getters.getOperadorData.id_grupo == 1) {
        this.$router.replace(`/pdv`);
      } else {
        this.$router.replace(`/dashboard`);
      }
    }
  },
  created() {
    this.$store.dispatch("activeLoader", false);
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.logar();
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
    logar() {
      this.loading = true;
      this.$http
        .post(`/login`, this.dados)
        .then((r) => {
          if (r.data.result) {
            const token = r.data.token;
            if (!token) {
              this.$router.replace("/login");
              this.$store.dispatch("showMessage", {
                show: true,
                color: "error darken-1",
                text: "Token inválido, execute o login novamente!",
              });
            }

            const { id_operador, id_agencia, login, level, id_grupo, foto } =
              decode(token);

            const usuario = {
              id_operador,
              id_agencia,
              login,
              level,
              id_grupo,
              foto,
            };
            this.$store.dispatch("PersistUser", usuario);
            this.operador = usuario;

            this.$store.dispatch("showMessage", {
              show: true,
              color: "success darken-1",
              text: r.data.result,
            });
            setTimeout(() => {
              this.loading = false;
              sessionStorage.setItem("auth-token", r.data.token);
              location.reload();
            }, 1000);
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error",
              text: r.data.error,
            });
            this.loading = false;
          }
        })
        .catch((e) => {
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error",
            text: e.message,
          });
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>
